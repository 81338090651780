var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    { attrs: { prominent: "", border: "top", color: "accent", dark: "" } },
    [
      _c("p", [
        _vm._v(
          " A NATIONAL AND REGIONAL CRIMINAL SEARCH WAS CONDUCTED THROUGH A MULTI-STATE DATABASE, WHICH CONSISTS OF STATE PAROLE RECORDS, COUNTY COURT RECORDS, OREGON ECOURTS AND SOME CRIMINAL INDEXES. RECORDS MAY BE LIMITED OR MISSING IN SOME STATES AND CAN NOT BE GUARANTEED. SEARCHING ANY ADDITIONAL LOCAL OR COUNTY RECORDS ARE ALWAYS THE BEST PRACTICE TO REVIEW CRIMINAL ACTIVITY. A MULTI-STATE SEX OFFENDER DATABASE AND OFAC/PATRIOT ACT/TERRORIST DATABASE WAS SEARCHED. THE NSOPR SEX OFFENDER SITE WAS SEARCHED (NON XPRESS SEARCHES ONLY). "
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }