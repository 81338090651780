<template>
    <v-alert prominent border="top" color="accent" dark>
        <p>
            A NATIONAL AND REGIONAL CRIMINAL SEARCH WAS CONDUCTED THROUGH A
            MULTI-STATE DATABASE, WHICH CONSISTS OF STATE PAROLE RECORDS, COUNTY
            COURT RECORDS, OREGON ECOURTS AND SOME CRIMINAL INDEXES. RECORDS MAY
            BE LIMITED OR MISSING IN SOME STATES AND CAN NOT BE GUARANTEED.
            SEARCHING ANY ADDITIONAL LOCAL OR COUNTY RECORDS ARE ALWAYS THE BEST
            PRACTICE TO REVIEW CRIMINAL ACTIVITY. A MULTI-STATE SEX OFFENDER
            DATABASE AND OFAC/PATRIOT ACT/TERRORIST DATABASE WAS SEARCHED. THE
            NSOPR SEX OFFENDER SITE WAS SEARCHED (NON XPRESS SEARCHES ONLY).
        </p>
    </v-alert>
</template>
<script>
export default {
    name: "crime-disclaimer",
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>